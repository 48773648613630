.mobile-record-display .white-space-pre-wrap {
	white-space: pre-wrap;
}

/* This is needed to create a sticky footer at the bottom of the page. Should not be
   enforced when printing otherwise may generate extra blank pages for mobile tickets. */
@media not print {
  body, div.wrapper {
    min-height:100vh;
  }

  .flex-fill {
    flex:1 1 auto;
  }
}

@media print {
  /* PMGR-11135 When printing, force the background and text to be black and white. (Note that the footer is
   hard styled to be dark background and light text, regardless of theme, which is why we need to target
   those selectors so specifically) */
  body, footer .bg-dark.text-light {
    color: black !important;
    background-color: white !important;
  }
  
  /* remove wasted footer space when printing */
  footer .pt-5 {
    padding-top: 0 !important;
  }
  
  /* PMGR-11361 Allows for different column sizing when printing */
  .col-print-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

	/* Prevent a single ticket from being split across two pages */
	.mobile-record-display .mobile-record {
    page-break-inside: avoid;
  }

  /* Force links and ticket separators to black */
  .mobile-record-display a {
      color: black !important;
      text-decoration: none !important;
  }

  .mobile-record-display .border-bottom {
      border-bottom-color: black !important;
  }
}